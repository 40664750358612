// 动态变化的数字
const directive = {
    inserted(el, binding) {
        // 最终要显示的数字
        // let finalNum = el.innerText;
        let initNum = Number(binding.value.count);
        // console.log("finalNum: ", finalNum);
        // 动画间隔
        let rate = 1000;
        // 步长,数据增加间隔
        let step = 1;
        // 初始值
        // let count =Json.parse(JSON.stringify(finalNum));
        let timer = setInterval(() => {
            if (initNum < 1) {
                clearInterval(timer);
                timer = null;
            } else {
                initNum = binding.value.tag ? initNum + step : initNum - step;
            }
            el.innerText = minSecond(initNum);
        }, rate);
        // clearInterval(timer);
        // timer = null;
        // clearInterval(timer)
    },
};
function minSecond(val) {
    // let second = Math.floor(n % 60);
    // if (second < 10) {
    //     second = "0" + second;
    // }
    // let a = null;
    // if (Math.floor(n / 60) < 10) {
    //     a = "0" + Math.floor(n / 60) + "分" + second + "秒";
    // } else {
    //     a = Math.floor(n / 60) + "分" + second + "秒";
    // }
    //小于60秒 直接返回秒
    if (val < 60) {
        return val + "秒";
    }
    let minuteTime = 0; // 分
    let hourTime = 0; // 小时
    let result = "";

    //如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(String(val / 60));
    //获取秒数，秒数取佘，得到整数秒数
    let newSecondTime = parseInt(String(val % 60));
    //如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
        //获取小时，获取分钟除以60，得到整数小时
        hourTime = parseInt(String(minuteTime / 60));
        //获取小时后取佘的分，获取分钟除以60取佘的分
        minuteTime = parseInt(String(minuteTime % 60));
    }
    //存在分钟
    if (minuteTime > 0) {
        result = `${minuteTime}分${newSecondTime}秒`;
    }
    //存在小时
    if (hourTime > 0) {
        result = `${hourTime}小时${minuteTime}分${newSecondTime}秒`;
    }
    return result;
    // return a;
}
export default directive;
